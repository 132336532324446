import React,{useState,useEffect} from 'react';
import {navigate} from 'gatsby'
import {Routes} from '../../../utils/route'
import {Spin } from 'antd';
const Safety=()=>{
    const [loading, setLoading] = useState (true);
    useEffect(()=>{
        const callback=(val)=>{
            if(val===true){
                setLoading(false);
                navigate('/yard/safety/safety_part_a')
            }}
          Routes('yard',callback)
    
    },[])
    if(!loading){
    return(
        <div>Redirect to Home</div>
    )
}else{
    return(<div className="spinner-main"><Spin/></div>)
  }
}
export default Safety